import Link from 'next/link'

export const ru = {
  BLOG_TITLE: 'Блог Александра Сидоренко',
  BLOG_DESCRIPTION:
    'Блог Александра Сидоренко с заметками и полноценными статьями о разработке, музыке и жизни',
  CREATIVITY_TITLE: 'Творчество Александра Сидоренко',
  CREATIVITY_DESCRIPTION:
    'Страница с публикациями моего творчества. Я занимаюсь музыкой, пишу стихи и рассказы.',
  ARIA_THEME_SWITCH: 'Переключатель темы',
  MENU_ABOUT_ME: 'Обо мне',
  MENU_BLOG: 'Блог',
  MENU_CREATIVITY: 'Творчество',
  FIRSTNAME: 'Александр',
  LASTNAME: 'Сидоренко',
  FULL_NAME: 'Александр Сидоренко',
  CITY: 'Новосибирск',
  COUNTRY: 'Россия',
  PUBLISHED: 'Опубликовано',
  CREATED: 'Создано',
  STORY: 'Рассказ',
  POEM: 'Стих',
  POETRY: 'Подборка стихов',
  SINGLE: 'Сингл',
  EP: 'EP',
  ALBUM: 'Альбом',
  WORK: 'Произведение',
  ARTICLE: 'Статья',
  RIGHTS: 'Все права защищены',
  NOT_FOUND: 'Страница не найдена',
  BACK_TO_MAIN_PAGE: 'Перейти на главную',
  UNKNOWN_ERROR: 'Неизвестная ошибка',
  RESTART: 'Обновить страницу',
  TW_ADULTHOOD: 'Материал предназначен для лиц старше 18 лет.',
  TW_RELIGION:
    'Данное сообщение (материал), описывает душевные терзания разочарованного в жизни человека, выполняющим функции лирического героя и не несёт в себе цели оскорбления чувств верующих. Если вы чувствительны к этой теме немедленно прекратите чтение страницы и закройте вкладку браузера.',
  TW_ADDICTION:
    'В данном материале упомянуты реалистичные сцены употребления алкоголя, табачных изделий и (или) других наркотических или психоактивных веществ. Их употребление опасно для здоровья и (или) запрещено законом.',
  TW_TRANSLATION: 'Этот текст был переведен DeepL с неродного для меня языка',
  AVATAR_ALT: 'Александр Сидоренко в мексиканской шляпе и с банджо в руках',
  KEYWORDS:
    'Александр Сидоренко, программист, усопший вождь, взломщик, хакер, батюшка Ленин, Ленин, джаваскриптер, бэкэндер',
  HEAD_DESCRIPTION:
    'Программист, усопший вождь, взломщик. Участник хакатонов и CTF. Основатель Blurred Education Хочу сыграть Летова на всех струнных и разработать бомбический проект с Blurred Technologies.',
  DESCRIPTION: (
    <>
      &quot;Это алхимия!&quot; - говорю я, когда пишу код. Программист, усопший
      вождь, взломщик. Участвовал в хакатонах, соревнованиях по информационной
      безопасности и вел образовательный проект Blurred Education. Хочу сыграть
      ООО &quot;Моя оборона&quot; на всех струнных музыкальных инструментах
      (сейчас сыграл на шести) и разработать бомбический проект с командой{' '}
      <Link href='https://blur.tech/'>Blurred Technologies</Link>.
    </>
  ),
}
